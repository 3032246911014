import {Link} from "react-router-dom";

export const massagesProductList = [
    {
        id: "massage_relaxant",
        tags: ["massage"],
        title: "Massage relaxant",
        subtitle: "Bien-etre",
        image: require("assets/img/products/massages/massage_relaxant.jpg"),
        description: "Une détente en profondeur du corps et de l'esprit.",
        sessionsInfos: [
            {
                duration: 60,
                price: 80
            },
            {
                duration: 90,
                price: 120
            },
            {
                duration: 120,
                price: 160
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser. (à disposition plaid et coussins)</p>
                    <p>Ce massage relaxant se compose d’un massage musculaire, psycho-émotionnel, énergétique. Vous avez
                        plus qu’à profiter pour vous détendre.</p>
                    <p>Le massage bien-être du corps complet est anti-stress par excellence.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Mettre un terme au processus de déséquilibre de l’organisme.</li>
                        <li>Rétablir l’harmonie entre les différents flux de l’organisme.</li>
                        <li>Evacuer le stress en débloquant les crispations des muscles.</li>
                        <li>Relaxer le corps et l’esprit, l’état de bien-être et le sommeil.</li>
                        <li>Revitaliser le corps en lui donnant plus de vigueur.</li>
                        <li>Soulager les désagréments d’ordre physique.</li>
                        <li>Calmer les troubles nerveux.</li>
                        <li>Rééquilibrer le flux d’énergie circulant dans l’organisme.</li>
                        <li>Améliore la circulation de l’énergie, elle stimule les défenses immunitaires</li>
                    </ul>

                    <p>Il calme le système nerveux, il favorise la relaxation, l’état de bien-être.</p>
                    <p>Il soulage les tensions physiques et mentales.</p>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Fièvre</li>
                        <li>Maladies infectieuses</li>
                        <li>Infection cutanée contagieuses (psoriasis, mycoses, verrues…)</li>
                        <li>Fractures et blessures récentes (Possibilité de ne pas masser sur cette zone)</li>
                        <li>Troubles circulatoires (phlébite, varice, trombose, hématone…)</li>
                        <li>Grossesse</li>
                        <li>Pathologies : cancer, épilepsie, diabète.. uniquement sur l’accord du médecin traitant</li>
                    </ul>

                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>
        }
    },
    {
        id: "massage_energetique",
        tags: ["massage", "holistic"],
        title: "Massage énergétique",
        subtitle: "Équilibre & revitalisation",
        image: require("assets/img/products/holistic/energetic_treatment.jpg"),
        description: "Plongez dans une expérience unique alliant massage, énergie et relaxation profonde pour rééquilibrer vos chakras, apaiser l’âme et revitaliser le corps.",
        sessionsInfos: [
            {
                duration: 75,
                price: 100
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Tout d’abord, je vous pose une série de questions pour aborder la séance en pleine conscience.</p>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser. (à disposition plaid et coussins).</p>
                    <p>Pendant le massage une intention énergétique pour rééquilibrer le flux énergétique, les chakras.
                        Un moment magique pour soulager les maux de l’âme en travaillant le corps. Comme tous
                        massages, il apporte un moment de détente, je le combine avec diverses techniques (hypnose
                        corporelle, apposition des mains, travail de réflexologie.). Il peut avoir utilisation de pierres semi-
                        précieuses ou de bâton énergétique ou l’utilisation de son vibratoire (timbale, bol tibétain..).</p>
                    <p>Chaque séance est un moment unique et personnalisé selon vos besoins.</p>
                    <p>En fin de séance, un moment d’échange.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Amélioration de la circulation lymphatique</li>
                        <li>Nettoyer des mémoires cellulaires</li>
                        <li>Détendre les muscles, les tissus musculaires</li>
                        <li>Harmoniser, réguler le flux énergétique</li>
                        <li>Rééquilibrer les chakras</li>
                        <li>Stimulation des défenses immunitaires</li>
                    </ul>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Fièvre</li>
                        <li>Maladies infectieuses</li>
                        <li>Insuffisance cardiaque</li>
                        <li>Thrombose veineuse profonde et phlébite : provoquer la migration des caillots et aggraver la situation</li>
                        <li>Pathologies : cancer sur l’accord du médecin traitant</li>
                    </ul>

                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>
        }
    },
    {
        id: "massage_lomilomi",
        tags: ["massage"],
        title: "Massage lomilomi",
        subtitle: "Harmonisant",
        image: require("assets/img/products/massages/massage_lomilomi.jpg"),
        description: "Rituel hawaïen aux mouvements fluides et enveloppants, procurant relaxation et harmonie.",
        sessionsInfos: [
            {
                duration: 60,
                price: 88
            },
            {
                duration: 90,
                price: 130
            },
            {
                duration: 120,
                price: 174
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser. (à disposition plaid et coussins). L’utilisation de
                        musique hawaiienne.</p>
                    <br/><br/>
                    <p>La signification de <b>Lomilomi</b> est de « casser en petits morceaux », écraser, frotter, frictionner,
                        malaxer.</p>
                    <br/>
                    <p>Ce massage est comme une danse, les mouvements sont fluides et rythmés, une sensation liée aux
                        éléments : l’eau, l’air, terre... Une pratique qui allie la relaxation, la spiritualité, la détente et
                        l’autoguérison.</p>
                    <br/>
                    <p>
                        Le massage est dans les traditions hawaiiennes dans le respect des connaissances des anciens,
                        c’est un massage qui peut être doux, lent, fort en fonction de vos besoins au rythme au des vagues,
                        de balancements. Ce massage ancestral considérer comme un massage thérapeutique, contribue au
                        bien-être. Dans la culture hawaiienne, <i>« il est essentiel d’avoir un corps sain, nettoyé
                        régulièrement de ses toxines physiques ou émotionnelles pour être en communion avec tout ce qui
                        nous entoure. »</i>
                    </p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Détendre le corps / Évacuer le stress</li>
                        <li>Augmente la circulation sanguine et dynamise la circulation lymphatique par la dilatation des vaisseaux</li>
                        <li>Apaise le système nerveux</li>
                        <li>Améliore la tonicité musculaire et facilite les mouvements</li>
                        <li>Agit comme un drainant, élimine les toxines stockées dans le corps et le surplus en eau</li>
                        <li>Contribue à l’équilibre du corps et de l’esprit</li>
                        <li>Harmonisation des différents systèmes</li>
                        <li>Rééquilibrage énergétique</li>
                        <li>Dynamise le métabolisme général</li>
                    </ul>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Fièvre</li>
                        <li>Maladies infectieuses</li>
                        <li>Inflammation / Ostéoporose</li>
                        <li>Température corporelle anormale</li>
                        <li>Varices / Œdème</li>
                        <li>Intoxication</li>
                        <li>Fatigue chronique (30 min maximum)</li>
                        <li>Insuffisance cardiaque</li>
                        <li>Hernie et hernies discales</li>
                        <li>Problèmes de peau : coupures, brûlures, gelures, eczéma, irritations diverses</li>
                        <li>Scoliose et autres pathologies vertébrales</li>
                        <li>Grossesse : éviter le drainage sur les zones abdominales et pelviennes</li>
                        <li>Thrombose veineuse profonde et phlébite : provoquer la migration des caillots et aggraver la situation</li>
                        <li>Pathologies : cancer (sur l’accord du médecin traitant)</li>
                    </ul>
                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>
        }
    },
    {
        id: "massage_lymphatique",
        tags: ["massage"],
        title: "Massage drainage lymphatique",
        subtitle: "Fluidité",
        image: require("assets/img/products/massages/massage_lymphatique.jpg"),
        description: "Revitalisation douceur: pour une Harmonie Corporelle Totale.",
        sessionsInfos: [
            {
                duration: 45,
                price: 75
            },
            {
                duration: 60,
                price: 95
            },
            {
                duration: 90,
                price: 150
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser. (à disposition plaid et coussins)</p>
                    <br/>
                    <p>Le drainage lymphatique est une technique de massage qui trouve ses origines au XXe siècle. Il a
                        été développé par Docteur Emil Vodder, pour traiter les troubles lymphatique, tels que l’œdème,
                        les problèmes circulatoires et les inflammations. Le drainage lymphatique est utilisé en post-
                        opératoire, de la gestion de rétention d’eau et de la cellulite, de la détoxication de
                        l’organisme et de la relaxation.</p>
                    <br/>
                    <p>Le système lymphatique remplit plusieurs fonctions :</p>
                    <br/>
                    <ul>
                        <li>Le drainage des excès de liquide des tissus pour maintenir l’équilibre hydrique</li>
                        <li>La filtration de la lymphe pour éliminer les déchets, les toxines et les agents infectieux
                        </li>
                        <li>La circulation de nutriments, des globules blancs et des hormones à travers le corps</li>
                        <li>La production de cellules immunitaires pour combattre les infections et renforcer les défenses du corps.
                        </li>
                    </ul>
                </>,
            bienfaits:
                <>
                    <p>La méthode est basée sur un drainage dynamique qui traite à la fois la partie veineuse et la
                        partie lymphatique. Cette pratique est inspirée par le style brésilien (RF) avec une approche
                        réfléchie et intentionnée.</p><br/>
                    <ul>
                        <li>Détoxification : drainage des toxines et des déchets.</li>
                        <li>Amélioration de la circulation lymphatique.</li>
                        <li>Meilleure distribution des nutriments et des hormones du corps.</li>
                        <li>Soulagement des troubles circulatoires</li>
                        <li>Soulagement des jambes lourdes, des varices et la cellulite.</li>
                        <li>Réduction de l’œdème</li>
                        <li>Diminution de la rétention d’eau</li>
                        <li>Stimulation des défenses immunitaires</li>
                    </ul>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Fièvre</li>
                        <li>Maladies infectieuses</li>
                        <li>Insuffisance cardiaque</li>
                        <li>Grossesse : éviter le drainage sur les zones abdominales et pelviennes</li>
                        <li>Thrombose veineuse profonde et phlébite : peut potentiellement provoquer la migration des caillots et aggraver
                            la situation</li>
                        <li>Pathologies : cancer sur l’accord du médecin traitant</li>
                    </ul>
                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>,
        }
    },
    {
        id: "massage_assis",
        title: "Massage amma-assis",
        subtitle: "Relaxant",
        image: require("assets/img/products/massages/massage_assis.jpg"),
        description: "Evacuez votre stress, essayez-vous à un moment de détente pour favoriser une meilleure concentration.",
        durationEnterprise: "15-20 min",
        descriptionEnterprise: "Technique habillée assis sur chaise spéciale ergonomique pour une détente du dos, trapèze, nuque, bras, mains et tête.",
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser. (à disposition plaid et coussins)</p>
                    <p>Ce massage relaxant se compose d’un massage musculaire, psycho-émotionnel, énergétique. Vous avez
                        plus qu’à profiter pour vous détendre.</p>
                    <p>Le massage bien-être du corps complet est anti-stress par excellence.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Mettre un terme au processus de déséquilibre de l’organisme.</li>
                        <li>Rétablir l’harmonie entre les différents flux de l’organisme.</li>
                        <li>Evacuer le stress en débloquant les crispations des muscles.</li>
                        <li>Relaxer le corps et l’esprit, l’état de bien-être et le sommeil.</li>
                        <li>Revitaliser le corps en lui donnant plus de vigueur.</li>
                        <li>Soulager les désagréments d’ordre physique.</li>
                        <li>Calmer les troubles nerveux.</li>
                        <li>Rééquilibrer le flux d’énergie circulant dans l’organisme.</li>
                        <li>Améliore la circulation de l’énergie, elle stimule les défenses immunitaires</li>
                    </ul>

                    <p>Il calme le système nerveux, il favorise la relaxation, l’état de bien-être.</p>
                    <p>Il soulage les tensions physiques et mentales.</p>
                </>,
        }
    },
    {
        id: "swedish_massage",
        title: "Massage suédois",
        tags: ["massage"],
        subtitle: "Dynamisme",
        image: require("assets/img/products/massages/massage_suedois.jpg"),
        description: "Un massage dynamique qui consiste en un enchaînement de manœuvres exécutées sur la musculature et les articulations.",
        sessionsInfos: [
            {
                duration: 60,
                price: 80
            },
            {
                duration: 90,
                price: 120
            },
            {
                duration: 120,
                price: 160
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser. (à disposition plaid et coussins)</p>
                    <p>Le massage suédois est un des grands classiques du massage à l’huile. Il est efficace pour
                        dénouer le dos et la nuque, le massage suédois est néanmoins un massage complet du corps, des
                        pieds au cuir chevelu et jusqu’au bout des
                        doigts, sur le dos puis le ventre.</p>
                    <p>Le massage suédois est une technique dynamique qui consiste en un enchaînement de manœuvres
                        exécutées principalement sur la musculature et les articulations. Les techniques du massage
                        suédois ont pour but de faire circuler le
                        sang vers le cœur.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Favorise la circulation sanguine et lymphatique.</li>
                        <li>Aide et élimine les toxines</li>
                        <li>Relâche les tensions en général</li>
                        <li>Raffermit les muscles</li>
                        <li>Assouplit les articulations</li>
                        <li>Soulage certaines tensions liées au stress</li>
                        <li>Adoucit et assouplit la peau</li>
                        <li>Entretient le bien-être et renforce la vitalité</li>
                    </ul>

                    <p>Il calme le système nerveux, il favorise la relaxation, l’état de bien-être.</p>
                    <p>Il soulage les tensions physiques et mentales.</p>
                    <p>Il atténue voire supprime, les noeuds, zones de tension et de douleurs liées aux tensions
                        concernées.</p>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Fièvre</li>
                        <li>Maladies infectieuses</li>
                        <li>Infection cutanée contagieuses (psoriasis, mycoses, verrues…)</li>
                        <li>Fractures et blessures récentes (Possibilité de ne pas masser sur cette zone)</li>
                        <li>Troubles circulatoires (phlébite, varice, trombose, hématone…)</li>
                        <li>Grossesse</li>
                        <li>Pathologies : cancer, épilepsie, diabète... uniquement sur l’accord du médecin traitant</li>
                    </ul>

                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>,
        }
    },
    {
        id: "massage_prenatal",
        tags: ["massage"],
        title: "Massage prénatal",
        subtitle: "Creer un lien avec bébé",
        image: require("assets/img/products/massages/massage_prenatal.jpg"),
        description: "Un massage tout en douceur, qui favorise le bien-être du bébé mais aussi de la maman.",
        sessionsInfos: [
            {
                duration: 60,
                price: 88
            },
            {
                duration: 90,
                price: 130
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage prénatal se pratique sur une table de massage. Vous êtes recouverte de serviettes qui
                        servent à découvrir au fur et mesure les parties massés. (accessoires : coussin, coussin
                        d’allaitement, bouillote, plaid)</p>
                    <p>Ce massage est pour le 2eme et 3eme trimestre de la grossesse. </p>
                    <p>Au 2eme trimestre : Massage du dos (position latéral), massage ventre latéral et horizontal
                        (selon votre l’état physique), jambe, décolleté, épaule, bras, mains.</p>
                    <p>Au 3eme trimestre : Massage du dos (position latéral), massage du ventre latéral, épaule, bras,
                        main. </p>
                    <p>Chaque séance est unique et en fonction de vos besoins.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Réduire le stress, la nervosité et l’anxiété</li>
                        <li>Soulager les tensions musculaires et articulaires</li>
                        <li>Soulager les maux de tête et les douleurs au dos</li>
                        <li>Améliorer l’oxygénation des tissus superficiels et des muscles</li>
                        <li>Améliore la qualité du sommeil</li>
                        <li>Améliore la circulation sanguine et lymphatique : réduction des gonflements et œdèmes</li>
                        <li>Diminuer les symptômes de la dépression</li>
                        <li>Réguler la qualité des hormones</li>
                        <li>Soulager les douleurs du nerf sciatique</li>
                        <li>Augmenter le plaisir du bébé qui ressent les sensations à travers de la paroi abdominal</li>
                        <li>Créer un lien entre la mère et le bébé grâce à la prise de conscience et communication avec
                            le bébé
                        </li>
                    </ul>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Diabète</li>
                        <li>Pertes importantes (sanguines ou aqueuses)</li>
                        <li>Hypertension artérielle</li>
                        <li>Protéinurie</li>
                        <li>Thromboses</li>
                        <li>Maladies contagieuses</li>
                        <li>Fièvre</li>
                        <li>Vomissements</li>
                        <li>Douleurs abdominales</li>
                        <li>Nausées matinales</li>
                        <li>Diarrhée</li>
                        <li>Œdèmes importants</li>
                        <li>Eruption cutanée (ecchymoses, inflammation)</li>
                        <li>Tout état maladif</li>
                    </ul>

                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>

                    <div className={"section text-center"}>
                        <img alt="Ambassadrisse massage bulle d'amour"
                             src={require("assets/img/certifications/bulle_amour.png")}/>
                    </div>
                </>
        }
    },
    {
        id: "ayurvedic",
        title: "Massage ayurvédique Abhyanga",
        tags: ["massage"],
        subtitle: "Individualisé",
        image: require("assets/img/products/massages/massage_ayurvedic.jpg"),
        description: "Issu de la médecine traditionnelle Indienne, cette technique de massage unique est réputée pour ses vertues anti-stress.",
        sessionsInfos: [
            {
                duration: 60,
                price: 88
            },
            {
                duration: 90,
                price: 130
            },
            {
                duration: 120,
                price: 174
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap qui sert à
                        découvrir au fur et à mesure les parties à masser avec l’huile indienne (Mahanarayan,
                        Dhavantaram..) chaude. (à disposition plaid et
                        coussins)</p>
                    <br/>
                    <p>Le massage ayurvédique fait partie intégrante de l’Ayurvéda comme une pratique sacrée,
                        rééquilibre les trois Doshas: Vata, Pitta, Kapha. C’est un véritable travail énergétique. </p>
                    <p>Ce massage bien-être est anti-stress par excellence, et recommandé de 16 à 75 ans.</p>
                    <br/>
                    <p>Cette séance que je vous propose est issue de la pure tradition Indienne. Ma <Link
                        to="/about-me#formations" className="text-primary"><b>formation</b></Link> vous garantie une
                        session en plein accord avec ces traditions.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Rééquilibre les 3 Doshas: VATA, PITTA, KAPHA</li>
                        <li>Rétablit l’harmonie entre les différents flux de l’organisme.</li>
                        <li>Evacue le stress en débloquant les crispations des muscles.</li>
                        <li>Relaxer le corps et l’esprit, l’état de bien-être et le sommeil.</li>
                        <li>Revitalise le corps en lui donnant plus de vigueur.</li>
                        <li>Effet détox, drainant</li>
                        <li>Favorise la circulation sanguine</li>
                        <li>Favorise la mémoire, la digestion</li>
                        <li>Soulage les désagréments d’ordre physique.</li>
                        <li>Calme les troubles nerveux, calme l’anxiété.</li>
                        <li>Rééquilibre le flux d’énergie circulant dans l’organisme.</li>
                        <li>Améliore la circulation de l’énergie, elle stimule les défenses immunitaires</li>
                    </ul>
                    <p>Il calme le système nerveux, il favorise la relaxation, le drainage, et l’état de bien-être.</p>
                    <p>Il soulage les tensions physiques et mentales.</p>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Fièvre</li>
                        <li>Maladies infectieuses</li>
                        <li>Infection cutanée contagieuses mycoses, verrues…)</li>
                        <li>Grossesse</li>
                        <li>Diarrhée</li>
                        <li>Migraine</li>
                        <li>Fractures et blessures récentes (Possibilité de ne pas masser sur cette zone)</li>
                        <li>Maladies des os de verre, parkinson de plus de 10 ans</li>
                        <li>Pas de massage pendant la radiothérapie, chimiothérapie</li>
                        <li>Pacemaker, épileptique uniquement sur l’accord du médecin traitant</li>
                    </ul>
                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>,
        }
    },
    {
        id: "stones_massage",
        tags: ["massage"],
        title: "Massage aux pierres semi-précieuses",
        subtitle: "Détente",
        image: require("assets/img/products/massages/massage_pierres.jpg"),
        description: "Utilisation des pierres sur le corps et le visage, pour un moment de détente inoubliable.",
        sessionsInfos: [
            {
                duration: 30,
                price: 45
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes recouvert d’un drap, des pierres sont
                        posés sur votre corps. (à disposition matelas chauffant…)</p>
                    <p>Le massage aux pierres semi-précieuses est un massage avec l’utilisation de pierres (apatite,
                        quartz rose, sodalite, labradorite, cristal de roche, aventurine, lapis lazulis, citrite, japse,
                        septoria, cornaline…) selon vos
                        besoins et des propriétés de la pierre.</p>
                    <p>Utilisation des pierres sur le corps et le visage, poser sur le corps et en effleurant les
                        parties du corps. </p>
                    <br/>
                    <p><b><i>Massage au choix: </i></b>dos, ventre ou jambes et pieds, et une partie visage et crânien.
                    </p>
                </>,
            bienfaits:
                <>
                    <p>
                        Les pierres ont chacune une composition chimique, une couleur et ses propres vertus
                        énergétiques. Grâce aux mouvements du massage, le corps se détend.
                    </p>
                    <p>Recommandés pour :</p>
                    <ul>
                        <li>État de fatigue nerveuse et stress</li>
                        <li>Trouble de sommeil</li>
                        <li>Manque de vitalité</li>
                        <li>Problème de circulation, troubles digestifs</li>
                        <li>Contractures et douleurs musculaires</li>
                    </ul>
                </>
        }
    },
    {
        id: "massage_bols_tibetains",
        tags: ["massage"],
        title: "Massage sonore aux bols tibétains",
        subtitle: "Relaxation",
        image: require("assets/img/products/massages/massage_bol_tibetains.jpg"),
        description: "Un voyage en résonance avec les sons, pour un moment de détente profonde.",
        sessionsInfos: [
            {
                duration: 60,
                price: 88
            },
            {
                duration: 120,
                price: 130
            }
        ],
        detailedDescription: {
            sessionContent:
                <>
                    <p>Le massage se pratique sur une table de massage. Vous êtes habillé (à disposition plaid et
                        coussins)</p>
                    <p>Ce massage sonore se compose de plusieurs bols chantants : full moon, 7 métaux, laiton et
                        cymbale. Vous avez plus qu’à profiter pour vous détendre.</p>
                    <p>Le massage sonore aux bols tibétains est un voyage en résonance avec les sons, un moment de
                        détente par excellence.</p>
                    <p>Selon vos besoins, idéalement prévoir plusieurs séances.</p>
                </>,
            bienfaits:
                <>
                    <ul>
                        <li>Rétablir l’harmonie entre les différents flux de l’organisme</li>
                        <li>Vos oreilles régulent votre tension</li>
                        <li>Développement de votre capacité de concentration, l’intuition</li>
                        <li>Relaxer le corps et l’esprit, l’état de bien-être et le sommeil</li>
                        <li>Réaccorder notre cerveau sur les ondes alpha</li>
                        <li>Résonance avec les métaux présents à l’intérieur de notre corps</li>
                        <li>Calmer les troubles nerveux</li>
                        <li>Rééquilibrer le flux d’énergie, libère les blocages physiques et émotionnels</li>
                        <li>Améliore la circulation de l’énergie, elle stimule les défenses immunitaires</li>
                    </ul>
                    <p>Tout est vibration.</p>
                </>,
            counterIndications:
                <>
                    <ul>
                        <li>Maladies infectieuses, présentant de la fièvre</li>
                        <li>Porteurs de pacemakers</li>
                        <li>Fractures récentes</li>
                        <li>Atteintes de troubles psychiatriques</li>
                        <li>Grossesse de moins de 4 mois ou de plus de 8 mois</li>
                        <li>Venant de subir une opération</li>
                        <li>Pathologies : cancer, troubles épileptiques… uniquement sur l’accord du médecin traitant
                        </li>
                    </ul>
                    <i>En cas de doute : <br/>
                        Demander l’avis de votre médecin traitant avant toute prise de rendez-vous</i>
                </>,
        }
    },
    {
        id: "massage_rebozo",
        tags: [],
        title: "Massage Rebozo",
        subtitle: "Bien-être maternel",
        image: require("assets/img/products/massages/massage_rebozzo.jpg"),
        description: "Le massage Rebozo prénatal offre un soutien physique et émotionnel, favorisant la détente musculaire, l'alignement du corps, et une connexion profonde entre la future mère et son bébé.",
        duration: "",
    }
]
